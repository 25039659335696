import React from 'react';
import { CssBaseline, Box, Stack } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { css } from '@emotion/react';

import { DashboardEffects } from 'effects';
import { useVisibleFooter, usePrintAmbulanceRecordButton } from 'hooks';

import {
  PatientsList,
  PatientOverview,
  PatientDetail,
  QrCodeImage,
  S3ImageListView,
  Footer,
  Header,
} from './src';
import {
  PatientInfoPrintButton,
  PrintAmbulanceRecordButton,
  FreeTextModalButton,
} from './src/GlobalButtons';

type DashboardProps = {
  isVisiblePrintAmbulanceRecordButton: boolean;
};

export const DashboardLayout = (props: DashboardProps) => {
  return (
    <Box
      css={css`
        height: 100%;
        padding-bottom: 1rem;
        overflow-y: auto;

        display: grid;
        grid-template-rows: auto auto minmax(0, 1fr);
        grid-template-columns: 1fr auto;
        grid-template-areas:
          'list  list'
          'overview  stack'
          'detail    stack';

        @media screen and (min-width: 1024px) {
          display: grid;
          grid-template-rows: auto minmax(0, 1fr);
          grid-template-columns: 2fr 3fr auto;
          grid-template-areas:
            'list     list   list'
            'overview detail stack';
        }
      `}
    >
      <Toaster position="top-left" />
      <Box
        css={css`
          grid-area: list;
        `}
      >
        <PatientsList />
      </Box>

      <PatientOverview
        css={css`
          grid-area: overview;
        `}
      />
      <PatientDetail
        css={css`
          grid-area: detail;
        `}
      />
      <Box
        css={css`
          grid-area: stack;
          display: grid;
          grid-template-rows: auto minmax(40px, 1fr) auto;
          gap: 1rem; /* 16px */

          margin: 1rem 1rem 0 1rem;
        `}
      >
        <Stack direction="column" alignItems="center" spacing={2}>
          <QrCodeImage />
          <FreeTextModalButton />
        </Stack>
        <S3ImageListView />
        <Box
          css={css`
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: auto auto;
            gap: 0.5rem; /* 8px */
          `}
        >
          {/* 「引継」ボタン */}
          <PatientInfoPrintButton />
          {/* 「活動記録表」ボタン、または「その他印刷」ボタン */}
          {props.isVisiblePrintAmbulanceRecordButton && <PrintAmbulanceRecordButton />}
        </Box>
      </Box>
    </Box>
  );
};

export const Dashboard = () => {
  const isVisibleFooter = useVisibleFooter();
  const { isVisible: isVisiblePrintAmbulanceRecordButton } =
    usePrintAmbulanceRecordButton();
  // const [isNewLayout, setNewLayout] = useState(true);

  // const handleChangeLayout = useCallback(() => {
  //   setNewLayout((b) => !b);
  // }, []);

  return (
    <Box>
      <CssBaseline />
      <Box
        css={css`
          display: grid;
          grid-template-rows: auto minmax(0, 1fr) auto;
          height: 100vh;
        `}
      >
        <Header />
        <main>
          <DashboardEffects />
          <DashboardLayout
            isVisiblePrintAmbulanceRecordButton={isVisiblePrintAmbulanceRecordButton}
          />
        </main>
        {isVisibleFooter ? <Footer /> : <></>}
      </Box>
    </Box>
  );
};
