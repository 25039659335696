/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listPatientsInfo = /* GraphQL */ `
  query ListPatientsInfo($hpUID: String!) {
    listPatientsInfo(filter: { qhpUID: { eq: $hpUID }, ac: { ne: 2 } }) {
      items {
        ambulancerecord_UID
        ac
        acclog
        ag
        am
        amUID
        amz {
          com
          cpss
          cvdd
          cvp
          cvr
          lvo
          vst
          mco
          freetextModalButtonLabel
          freetextModalContent
        }
        ar
        bd
        bt
        cc
        co
        cpf
        cpr
        cptd
        cr
        cvb
        cvf
        da
        db
        dc
        dd
        de
        dh
        di
        dp
        dq
        drcar_inquiry
        drheli_inquiry
        ea
        em
        ge
        gm
        gv
        ha
        hd
        ho
        hpUID
        id
        jc
        jn
        me
        na
        naf
        nsc
        of
        or
        ot
        pd
        ph
        pi
        pr
        qa
        qhpUID
        re
        rr
        sb
        se
        simultaneousInquiry
        sp
        sp2
        tff
        ty
        vt
        wp
        vitals {
          bt
          db
          of
          or
          pr
          rr
          sp
          sb
          sp2
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listImagesData = /* GraphQL */ `
  query ListImagesData($patientId: String) {
    listImagesData(filter: { patientId: { eq: $patientId } }) {
      items {
        id
        patientId
        isVideo
        videoUrl
        videoDuration
        videoWidth
        videoHeight
        createdAt
      }
    }
  }
`;

export const listInvitations = /* GraphQL */ `
  query ListInvitations($hpUID: String!) {
    listInvitations(hpUID: $hpUID) {
      id
      hpUID
      teamId
      teamName
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const getInvitationUrl = /* GraphQL */ `
  query GetInvitationUrl($invitationId: String!) {
    getInvitationUrl(invitationId: $invitationId) {
      url
    }
  }
`;

export const getHospitalUser = /* GraphQL */ `
  query GetHospitalUser($hpUID: String!, $userName: String!) {
    getHospitalUser(hpUID: $hpUID, userName: $userName) {
      hpUID
      userName
      hospitalName
      hostUrl
      latitude
      longitude
      qrCompressionFlag
      inquiryHistoryButtonFlag
      inquiryResponseButtonFlag
      ambulanceRecordButtonFlag
    }
  }
`;
