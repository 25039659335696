import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import authReducer from './ducks/auth/slice';
import routeReducer from './ducks/route/slice';
import hospitalReducer from './ducks/hospital/slice';
import invitationsReducer from './ducks/invitations/slice';
import patientsReducer, { patientsAdaper } from './ducks/patients/slice';
import imagesReducer, { imagesAdaper } from './ducks/s3images/slice';
import errorReducer from './ducks/error/slice';

const reducer = combineReducers({
  auth: authReducer,
  route: routeReducer,
  hospital: hospitalReducer,
  invitations: invitationsReducer,
  patients: patientsReducer,
  images: imagesReducer,
  error: errorReducer,
});

const store = configureStore({ reducer });
export type RootState = ReturnType<typeof reducer>;

/**
 * Selectors
 */
export const patientsSelectors = patientsAdaper.getSelectors(
  (state: RootState) => state.patients
);
export const imagesSelectors = imagesAdaper.getSelectors(
  (state: RootState) => state.images
);

export default store;
