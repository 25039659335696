/**
 * Import Layer
 */
import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@mui/material';

/**
 * Types Layer
 */
type RescueBadgeProps = {
  type: 'simultaneous' | 'transfer' | 'referral' | 'doctorheli' | 'doctorcar';
};

/**
 * Styles Layer
 */
const rescueBadgeStyel = (type: RescueBadgeProps['type']) => css`
  width: 32px;
  height: 32px;
  background-color: ${type === 'simultaneous'
    ? '#fb923c'
    : type === 'transfer'
    ? '#6fa4c3'
    : type === 'doctorheli'
    ? '#fc508e'
    : type === 'doctorcar'
    ? '#ff9417'
    : '#a18fff'};
  border-radius: ${type === 'referral' ? '16px' : '8px'};

  /* Content Center */
  display: grid;
  place-content: center;

  /* Text Style */
  text-align: center;
  font-size: ${type === 'simultaneous' || type === 'doctorheli' || type === 'doctorcar'
    ? '11px'
    : '18px'};
  line-height: ${type === 'simultaneous' || type === 'doctorheli' || type === 'doctorcar'
    ? '14px'
    : '28px'};
  font-weight: bold;
  font-family: 'Noto Sans JP', sans-serif;
  color: #292929;

  abbr {
    text-decoration-line: none;
  }
`;

/**
 * Presentational Layer
 */
export const RescueBadge: React.VFC<RescueBadgeProps> = React.memo(({ type }) => {
  return (
    <Box css={rescueBadgeStyel(type)} aria-label={type}>
      {type === 'simultaneous' ? (
        <div>
          <div>一斉</div>
          <div>要請</div>
        </div>
      ) : type === 'doctorcar' ? (
        <div>
          <div>DR</div>
          <div>カー</div>
        </div>
      ) : type === 'doctorheli' ? (
        <div>
          <div>ドク</div>
          <div>ヘリ</div>
        </div>
      ) : type === 'transfer' ? (
        <abbr title="転院搬送">転</abbr>
      ) : type === 'referral' ? (
        <abbr title="紹介患者">紹</abbr>
      ) : null}
    </Box>
  );
});
