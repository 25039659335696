/**
 * Import Layer
 */
import React from 'react';
import { css } from '@emotion/react';
import { RescueBadge } from './RescueBadge';

/**
 * Types Layer
 */
type RescueUIProps = {
  am?: string;
  simultaneousInquiry?: boolean | null;
  drcar_inquiry?: boolean | null;
  drheli_inquiry?: boolean | null;
  tff?: 0 | 1;
  nsc?: 0 | 1 | null;
};

/**
 * Presentational Layer
 */
export const RescueUI: React.VFC<RescueUIProps> = React.memo(
  ({ am, simultaneousInquiry, drcar_inquiry, drheli_inquiry, tff, nsc }) => {
    return (
      <div
        css={css`
          min-width: 136px;
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 8px;
        `}
      >
        <div
          css={css`
            margin-left: 4px;
            font-size: 18px;
            font-weight: bold;
            font-family: 'Noto Sans JP', sans-serif;

            /* truncate */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {am ?? '救急隊名不明'}
        </div>
        {simultaneousInquiry === true ? (
          <RescueBadge type={'simultaneous'} />
        ) : drheli_inquiry === true ? (
          <RescueBadge type={'doctorheli'} />
        ) : drcar_inquiry === true ? (
          <RescueBadge type={'doctorcar'} />
        ) : tff === 1 ? (
          <RescueBadge type={'transfer'} />
        ) : nsc === 1 ? (
          <RescueBadge type={'referral'} />
        ) : null}
      </div>
    );
  }
);
