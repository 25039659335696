/**
 * Import Layer
 */
import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItemButton, Divider, Box, Stack } from '@mui/material';
import { css } from '@emotion/react';

import { patientsSelectors } from 'states';
import { selectPatient, setFilterCondition } from 'states/ducks/patients/slice';
import { Sex as SexType } from 'states/ducks/patients/types';
import {
  AgeUI,
  ChiefComplaintUI,
  DatetimeUI,
  RescueUI,
  SexUI,
  NoData,
  FilterButton,
} from './src';
import { useDoctorHeliInquiry, useDoctorCarInquiry, usePatientsState } from 'hooks';
import { PrognosisButton, ReloadPatientsButton } from '../GlobalButtons';

/**
 * Types Layer
 */
export type PatientsListUIProps = {
  patients?: {
    id?: string;
    am?: string;
    ag?: number | null;
    se?: SexType;
    simultaneousInquiry?: boolean | null;
    drcar_inquiry?: boolean | null;
    drheli_inquiry?: boolean | null;
    pi?: string;
    tff?: 0 | 1;
    nsc?: 0 | 1 | null;
    createdAt?: string;
  }[];
  selectedPatientId?: string;
  onSelectPatient: (id: string) => void;
  filter: 'all' | 'doctorheli' | 'doctorcar' | 'rescue' | 'referral';
  enable_doctorheli_filter?: boolean;
  enable_doctorcar_filter?: boolean;
  onSelectFilter: (
    value: 'all' | 'doctorheli' | 'doctorcar' | 'rescue' | 'referral'
  ) => void;
};

/**
 * Styles Layer
 */
const rowStyel = (isSelected: boolean) => css`
  display: grid;
  grid-template-columns: auto 1fr auto auto 2fr;
  gap: 16px;
  height: 72px;
  ${isSelected && 'outline: 2px solid #FFF503;'}
`;

const listContainerStyle = css`
  min-height: 0px;
  max-height: 60vh;
  height: 168px;
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    margin-right: 5px;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
  }
`;

/**
 * Presentational Layer
 */
export const PatientsListUI = React.memo((props: PatientsListUIProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement>(null);

  const mouseDownHandler: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      console.log('mousedown');
      if (listRef.current && resizerRef.current) {
        const y = event.clientY;
        const h = listRef.current.getBoundingClientRect().height;

        const mouseMoveHandler = (e: MouseEvent) => {
          console.log('mousemove');
          e.preventDefault();
          if (listRef.current) {
            const dy = e.clientY - y;
            listRef.current.style.height = `${h + dy}px`;
          }
        };

        const mouseUpHandler = () => {
          console.log('mouseup');
          if (listRef.current && resizerRef.current) {
            document.body.removeEventListener('mousemove', mouseMoveHandler);
            document.body.removeEventListener('mouseup', mouseUpHandler);

            listRef.current.style.removeProperty('pointer-events');
            listRef.current.style.removeProperty('border-bottom');
            resizerRef.current.style.removeProperty('border-top');
            resizerRef.current.style.removeProperty('border-bottom');
            document.body.style.removeProperty('user-select');
            document.body.style.removeProperty('cursor');
          }
        };

        document.body.addEventListener('mousemove', mouseMoveHandler, { passive: false });
        document.body.addEventListener('mouseup', mouseUpHandler);

        listRef.current.style.pointerEvents = 'none';
        listRef.current.style.borderBottom = '4px solid rgba(144, 202, 249, 0.7)';
        resizerRef.current.style.borderTop = '2px solid rgba(144, 202, 249, 0.7)';
        resizerRef.current.style.borderBottom = '2px solid transparent';
        document.body.style.userSelect = 'none';
        document.body.style.cursor = 'row-resize';
      }
    },
    []
  );

  const touchStartHandler: React.TouchEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      console.log('touchstart');
      if (listRef.current && resizerRef.current) {
        let y = event.touches[0].clientY;
        const h = listRef.current.getBoundingClientRect().height;

        const contextmenuHandler = (e: Event) => {
          e.preventDefault();
        };

        const touchMoveHandler = (e: TouchEvent) => {
          console.log('touchmove');
          e.preventDefault();
          if (listRef.current) {
            const dy = e.touches[0].clientY - y;
            listRef.current.style.height = `${h + dy}px`;
          }
        };

        const touchEndHandler = () => {
          console.log('touchend');
          if (listRef.current && resizerRef.current) {
            document.body.removeEventListener('touchmove', touchMoveHandler);
            document.body.removeEventListener('touchend', touchEndHandler);
            document.body.removeEventListener('contextmenu', contextmenuHandler);

            listRef.current.style.removeProperty('pointer-events');
            listRef.current.style.removeProperty('border-bottom');
            resizerRef.current.style.removeProperty('border-top');
            resizerRef.current.style.removeProperty('border-bottom');
            document.body.style.removeProperty('user-select');
            document.body.style.removeProperty('cursor');
          }
        };

        document.body.addEventListener('touchmove', touchMoveHandler, { passive: false });
        document.body.addEventListener('touchend', touchEndHandler);
        document.body.addEventListener('contextmenu', contextmenuHandler);

        listRef.current.style.pointerEvents = 'none';
        listRef.current.style.borderBottom = '4px solid rgba(144, 202, 249, 0.7)';
        resizerRef.current.style.borderTop = '2px solid rgba(144, 202, 249, 0.7)';
        resizerRef.current.style.borderBottom = '2px solid transparent';
        document.body.style.userSelect = 'none';
        document.body.style.cursor = 'row-resize';
      }
    },
    []
  );

  return (
    <Box
      css={css`
        background-color: black;
      `}
    >
      {/* 患者リストヘッダ */}
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        css={css`
          padding: 8px 16px 4px 16px;
        `}
      >
        {/* 患者リスト更新ボタン */}
        <ReloadPatientsButton />
        {/* 救急・紹介切り替えボタングループ */}
        <Stack direction="row" spacing={1} role="group" aria-label="filter">
          <FilterButton
            type={'all'}
            onSelectFilter={props.onSelectFilter}
            isSelected={props.filter === 'all'}
          />
          <FilterButton
            type={'rescue'}
            onSelectFilter={props.onSelectFilter}
            isSelected={props.filter === 'rescue'}
          />
          {props.enable_doctorheli_filter && (
            <FilterButton
              type={'doctorheli'}
              onSelectFilter={props.onSelectFilter}
              isSelected={props.filter === 'doctorheli'}
            />
          )}
          {props.enable_doctorcar_filter && (
            <FilterButton
              type={'doctorcar'}
              onSelectFilter={props.onSelectFilter}
              isSelected={props.filter === 'doctorcar'}
            />
          )}
          <FilterButton
            type={'referral'}
            onSelectFilter={props.onSelectFilter}
            isSelected={props.filter === 'referral'}
          />
        </Stack>
        {/* スペーサー */}
        <div
          css={css`
            flex-grow: 1;
          `}
        />
        {/* 予後入力ボタン */}
        <PrognosisButton />
      </Stack>

      {/* 患者リスト本体 */}
      <Box css={listContainerStyle} ref={listRef}>
        {props.patients === undefined || props.patients.length === 0 ? (
          <NoData />
        ) : (
          <List
            dense={true}
            css={css`
              padding: 2px;
            `}
          >
            {props.patients.map((item, index) => {
              const isSelected: boolean = props.selectedPatientId === item.id;
              return (
                <li key={index}>
                  <ListItemButton
                    aria-label={`listItemButton-${item.id}`}
                    alignItems="center"
                    key={item.id}
                    selected={isSelected}
                    onClick={() => props.onSelectPatient(item.id ?? '')}
                    css={rowStyel(isSelected)}
                  >
                    <DatetimeUI createdAt={item.createdAt} isSelected={isSelected} />
                    <RescueUI
                      am={item.am}
                      tff={item.tff}
                      nsc={item.nsc}
                      simultaneousInquiry={item.simultaneousInquiry}
                      drheli_inquiry={
                        props.enable_doctorheli_filter && item.drheli_inquiry
                      }
                      drcar_inquiry={props.enable_doctorcar_filter && item.drcar_inquiry}
                    />
                    <AgeUI ag={item.ag} />
                    <SexUI se={item.se} />
                    <ChiefComplaintUI pi={item.pi} />
                  </ListItemButton>
                  <Divider
                    css={css`
                      background-color: #ffffff;
                    `}
                    variant="middle"
                  />
                </li>
              );
            })}
          </List>
        )}
      </Box>

      {/* リサイザ */}
      <div
        css={css`
          width: 100%;
          height: 32px;
          background-color: #363636;
          display: grid;
          place-content: center;
          box-sizing: border-box;
        `}
        ref={resizerRef}
      >
        <div
          onMouseDown={mouseDownHandler}
          onTouchStart={touchStartHandler}
          css={css`
            border: solid rgba(144, 202, 249, 0.5) 1px;
            height: 28px;
            width: 28px;
            border-radius: 4px;
            display: grid;
            place-content: center;
            cursor: row-resize;
          `}
        >
          {/* Vertical Arrow Icon */}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 12V3.5M12 12L12 20.5"
              stroke="#90caf9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 3L8 7M12 21L8 17M12 3L16 7M12 21L16 17"
              stroke="#90caf9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </Box>
  );
});

/**
 * Container Layer
 */
export const PatientsList = () => {
  const dispatch = useDispatch();
  const { selectedPatientId, filterCondition } = usePatientsState();
  const docotor_heli_inquiry_flag = useDoctorHeliInquiry();
  const docotor_car_inquiry_flag = useDoctorCarInquiry();

  // Get Patatients Data from Store & Filtering
  const patients = useSelector(patientsSelectors.selectAll).filter((patient) => {
    switch (filterCondition) {
      case 'all':
        return true;
      case 'doctorheli':
        return patient.drheli_inquiry;
      case 'doctorcar':
        return patient.drcar_inquiry;
      case 'referral':
        return patient.nsc === 1;
      case 'rescue':
        return !(
          patient.nsc === 1 ||
          (docotor_heli_inquiry_flag && patient.drheli_inquiry) ||
          (docotor_car_inquiry_flag && patient.drcar_inquiry)
        );
      default:
        console.error('Unexpected filter condition');
        return true;
    }
  });

  const handleSelectPatient = useCallback(
    (id: string) => dispatch(selectPatient(id)),
    [dispatch]
  );

  const handleSelectFilterCondition = useCallback(
    (value: 'all' | 'doctorheli' | 'doctorcar' | 'rescue' | 'referral') =>
      dispatch(setFilterCondition(value)),
    [dispatch]
  );

  return (
    <PatientsListUI
      patients={patients}
      selectedPatientId={selectedPatientId}
      onSelectPatient={handleSelectPatient}
      filter={filterCondition}
      onSelectFilter={handleSelectFilterCondition}
      enable_doctorheli_filter={docotor_heli_inquiry_flag}
      enable_doctorcar_filter={docotor_car_inquiry_flag}
    />
  );
};
