import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import Observable from 'zen-observable';

import {
  setPatients,
  addPatient,
  updatePatient,
  dropPatient,
} from 'states/ducks/patients/slice';
import { setSubscriptionError } from 'states/ducks/error/slice';
import { listPatientsInfo } from 'graphql/queries';
import { onCreatePatientInfo, onUpdatePatientInfo } from 'graphql/subscriptions';
import { useHospital } from 'hooks';
import { Patient } from 'states/ducks/patients/types';

/**
 * Types Layer
 * ListPatientsQueryType: Query
 * CreatePatientsSubscriptionEvent: Create Subscription
 * UpdatePatientsSubscriptionEvent: Update Subscription
 */
export type ListPatientsQueryType = {
  listPatientsInfo: {
    items: Patient[] | [];
  };
};
export type CreatePatientInfoSubscriptionEventType = {
  value: {
    data: {
      onCreatePatientInfo: Patient;
    };
  };
};
export type UpdatePatientInfoSubscriptionEventType = {
  value: {
    data: {
      onUpdatePatientInfo: Patient;
    };
  };
};

/**
 * AppSync の Query / Subscription をモデル化
 */
export const PatientsModel = {
  listPatients: (hpUID: string): Promise<GraphQLResult<ListPatientsQueryType>> => {
    return API.graphql({
      query: listPatientsInfo,
      variables: { hpUID: hpUID },
    });
  },
  subscribeOnCreatePatient: (hpUID: string | null, ac: 0 | 1): Observable<object> => {
    return API.graphql({
      query: onCreatePatientInfo,
      variables: { hpUID: hpUID, ac: ac },
    });
  },
  subscribeOnUpdatePatient: (hpUID: string | null, ac: 0 | 1 | 2): Observable<object> => {
    return API.graphql({
      query: onUpdatePatientInfo,
      variables: { hpUID: hpUID, ac: ac },
    });
  },
};

export const ListPatientsEffect = () => {
  const { hpUID } = useHospital();
  const dispatch = useDispatch();

  useEffect(() => {
    const getPatiensData = async () => {
      if (hpUID !== null) {
        const response = (await PatientsModel.listPatients(
          hpUID
        )) as GraphQLResult<ListPatientsQueryType>;
        if (response.data) {
          const patientsData = response.data.listPatientsInfo.items;
          dispatch(setPatients(patientsData));
        }
      }
    };
    getPatiensData();
  }, [dispatch, hpUID]);
  return null;
};

export const CreateSubscriptionPatientEffect = React.memo((props: { ac: 0 | 1 }) => {
  const { hpUID } = useHospital();
  const dispatch = useDispatch();

  useEffect(() => {
    const client = PatientsModel.subscribeOnCreatePatient(hpUID, props.ac);
    const subscription = client.subscribe({
      next: (event: CreatePatientInfoSubscriptionEventType) => {
        const {
          value: { data },
        } = event;
        const patientsData = data.onCreatePatientInfo;
        dispatch(addPatient(patientsData));
      },
      error: () => {
        switch (props.ac) {
          case 0:
            dispatch(setSubscriptionError({ CreateSubscriptionPatientEffect_0: true }));
            break;
          case 1:
            dispatch(setSubscriptionError({ CreateSubscriptionPatientEffect_1: true }));
            break;
          default:
            break;
        }
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch, hpUID, props.ac]);
  return null;
});

export const UpdateSubscriptionPatientEffect = React.memo((props: { ac: 0 | 1 | 2 }) => {
  const { hpUID } = useHospital();
  const dispatch = useDispatch();

  useEffect(() => {
    const client = PatientsModel.subscribeOnUpdatePatient(hpUID, props.ac);
    const subscription = client.subscribe({
      next: (event: UpdatePatientInfoSubscriptionEventType) => {
        const {
          value: { data },
        } = event;
        const patientsData = data.onUpdatePatientInfo;
        if (props.ac === 0 || 1) dispatch(updatePatient(patientsData));
        if (props.ac === 2) dispatch(dropPatient(patientsData));
      },
      error: () => {
        switch (props.ac) {
          case 0:
            dispatch(setSubscriptionError({ UpdateSubscriptionPatientEffect_0: true }));
            break;
          case 1:
            dispatch(setSubscriptionError({ UpdateSubscriptionPatientEffect_1: true }));
            break;
          case 2:
            dispatch(setSubscriptionError({ UpdateSubscriptionPatientEffect_2: true }));
            break;
          default:
            break;
        }
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch, hpUID, props.ac]);
  return null;
});

export const PatientsEffect = () => {
  return (
    <>
      <ListPatientsEffect />
      <CreateSubscriptionPatientEffect ac={0} />
      <CreateSubscriptionPatientEffect ac={1} />
      <UpdateSubscriptionPatientEffect ac={0} />
      <UpdateSubscriptionPatientEffect ac={1} />
      <UpdateSubscriptionPatientEffect ac={2} />
    </>
  );
};
